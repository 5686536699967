import service from '@/utils/request'; // @Tags Fund
// @Summary 创建Fund
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Fund true "创建Fund"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /user_accounts/createFund [post]

export var createFund = function createFund(data) {
  return service({
    url: "/user_accounts/createFund",
    method: 'post',
    data: data
  });
}; // @Tags Fund
// @Summary 删除Fund
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Fund true "删除Fund"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /user_accounts/deleteFund [delete]

export var deleteFund = function deleteFund(data) {
  return service({
    url: "/user_accounts/deleteFund",
    method: 'delete',
    data: data
  });
}; // @Tags Fund
// @Summary 删除Fund
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除Fund"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /user_accounts/deleteFund [delete]

export var deleteFundByIds = function deleteFundByIds(data) {
  return service({
    url: "/user_accounts/deleteFundByIds",
    method: 'delete',
    data: data
  });
}; // @Tags Fund
// @Summary 更新Fund
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Fund true "更新Fund"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /user_accounts/updateFund [put]

export var updateFund = function updateFund(data) {
  return service({
    url: "/user_accounts/updateFund",
    method: 'put',
    data: data
  });
}; // @Tags Fund
// @Summary 用id查询Fund
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Fund true "用id查询Fund"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /user_accounts/findFund [get]

export var findFund = function findFund(params) {
  return service({
    url: "/user_accounts/findFund",
    method: 'get',
    params: params
  });
}; // @Tags Fund
// @Summary 分页获取Fund列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Fund列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /user_accounts/getFundList [get]

export var getFundList = function getFundList(params) {
  return service({
    url: "/user_accounts/getFundList",
    method: 'get',
    params: params
  });
};